<script>
import { mapGetters, mapState, mapActions } from "vuex";
import moment from "moment";
import AppEmptyState from "./empty-state.vue";
import AppAlert from "@/features/bootstrap/Alert";
import AppModal from "./modal";
import AppIcon from "@/features/general/Icons";

export default {
  name: "AppWaitingList",

  data() {
    return {
      dataModal: {
        id: 0,
        title: "",
        description: "",
        buttons: [
          {
            text: "",
            classes: "",
            function: () => {},
          },
        ],
      },
    };
  },

  components: {
    AppEmptyState,
    AppAlert,
    AppModal,
    AppIcon,
  },

  mounted() {
    moment.locale("pt-br");
    this.getWaitingList();
  },

  methods: {
    ...mapActions("WaitingList", [
      "setListData",
      "setMessages",
      "clearMessages",
    ]),
    ...mapActions("WaitingList", ["setLoading"]),

    /**
     * Faz a requisição dos produtos da lista de espera
     * @return {void}
     */
    async getWaitingList() {
      const path = "/products/let-me-know";
      await this.$http
        .get(path)
        .then((response) => {
          this.setListData({ list: response.data.message });
        })
        .catch(() => {
          this.setMessages({
            variant: "danger",
            text: this.langs.product["failure-load-waiting-list"],
          });
        });

      this.setLoading({ loading: false });
    },

    /**
     * Remove um item da lista de espera
     * @return {void}
     */
    async removeOneWitingList(data) {
      this.clearMessages();
      this.setLoading({ loading: true });
      const path = `/products/let-me-know/${data.hash}`;
      await this.$http
        .delete(path)
        .then(() => {
          this.setMessages({
            text: `<b>${data.name}</b> ${this.langs.product["has-been-removed-from-waiting-list"]}`,
            variant: "success",
          });

          const newList = this.list.filter((item) => {
            return item.let_me_know_id != data.let_me_know_id;
          });
          this.setListData({ list: newList });
        })
        .catch(() => {
          this.setMessages({
            variant: "danger",
            text: this.langs.product["failure-delete-waiting-list"],
          });
        });
      this.setLoading({ loading: false });
    },

    /**
     * Verifica se o item está disponivel para compra
     * @param {int} available_for_purchase Se o produto está disponível ou não
     * @return {boolean}
     */
    availableForPurchase(isAvaliable) {
      return isAvaliable == 1;
    },

    /**
     * Atribui a class badge
     * @param {int} available_for_purchase Se o produto está disponível ou não
     * @return {string} Classe css relativa ao tipo do selo
     */
    badgeType(available_for_purchase) {
      return this.availableForPurchase(available_for_purchase)
        ? "badge-success"
        : "badge-secondary";
    },

    /**
     * Define o texto que aparecerá dentro do badge
     * @param {int} available_for_purchase Se o produto está disponível ou não
     * @return {string} Texto relativo ao selo
     */
    badgeText(available_for_purchase) {
      return this.availableForPurchase(available_for_purchase)
        ? this.langs.product["available"]
        : this.langs.product["unavailable"];
    },

    /**
     * Define o texto do botão
     * @param {int} available_for_purchase Se o produto está disponível ou não
     * @return {string} Texto relativo botão
     */
    buttonText(available_for_purchase) {
      return this.availableForPurchase(available_for_purchase)
        ? this.langs.product["purchase"]
        : this.langs.product["show-product"];
    },

    /**
     * Define a ação do botão
     * @param {string} url É o link do produto dentro da loja
     * @return {undefined} Redireciona o usuário a url do parametro
     */
    actionButton(url) {
      window.location.href = url;
    },

    /**
     * Se houver data a formata para o padrão "LL"
     * @param {date} date É uma data
     * @return {date} Data recebida formatada
     */
    dateAdd(date) {
      return date && moment(date).format("LL");
    },

    /**
     * Checa se tem data valida
     * @param {string} date valor da data
     * @return {string}
     */
    haveData(date) {
      return date && date !== "0000-00-00";
    },

    /**
     * Atribui valores que serão usados no modal
     * @param {string} modalType Referente ao nome de um objeto dentro da variável "modais"
     * @param {object} modalData Objeto do produto da lista de pedido
     * @return {} Atribui o valor da variavel modal[modalType] a data() this.dataModal
     */
    setDataModal(modalType, modalData) {
      const modais = {
        "remove-one": {
          id: modalData?.let_me_know_id,
          title: this.langs.product["remove-product-from-list"],
          description: `<p>${this.langs.product["remove-notification-if-remove-product"]}<br /><br />${this.langs.product["allow-remove-product"]} <b>${modalData?.name}</b>?</p>`,
          buttons: [
            {
              classes:
                "app__button app__button--primary-outline app__button--no-margin app__waiting-list__button--second ",
              text: this.langs.product["cancel"],
            },
            {
              classes:
                "app__button app__button--primary app__button--no-margin app__waiting-list__button--second btn-danger",
              text: this.langs.product["remove"],
              function: () => this.removeOneWitingList(modalData),
            },
          ],
        },
      };

      return (this.dataModal = modais[modalType]);
    },
  },

  computed: {
    ...mapGetters(["isMobile", "langs"]),
    ...mapState("WaitingList", {
      loading: (state) => state.waitingList.loading,
      messages: (state) => state.waitingList.messages,
      list: (state) => state.waitingList.list,
    }),

    /**
     * Atribui uma classe apenas se estiver em um ambiente mobile
     * @return {string} Classe css relativa a versão mobile da tela
     */
    MobileWaitingList() {
      return this.isMobile ? "app__waiting-list__container-list__mobile" : "";
    },

    /**
     * Adiciona "display: none" enquanto apágina não carrega
     * @return {string} Atributo css inline
     */
    ifLoading() {
      return this.loading && "display: none";
    },
  },
};
</script>

<template>
  <section class="app__waiting-list">
    <header class="app__waiting-list__header">
      <h2
        class="app__waiting-list__title app__waiting-list__title--2"
        :class="{ app__loading: loading }"
      >
        {{ this.langs.sidebar_menu["waiting-list"] }}
      </h2>
    </header>
    <ul
      class="app__waiting-list__container-list"
      :class="MobileWaitingList"
      v-if="list && list.length > 0"
    >
      <app-alert :messages="messages" />
      <li
        class="app__waiting-list__item-list"
        v-for="(item, index) in list"
        :key="index"
      >
        <section class="d-flex" :class="{ app__loading: loading }">
          <img
            class="app__waiting-list__item-list__image"
            v-if="item.ProductImage?.[0]?.thumbs?.['180']?.https"
            :src="item.ProductImage[0].thumbs['180'].https"
          />
          <app-icon
            class="app__waiting-list__item-list__image app__waiting-list__item-list__image__icon"
            name="eye-slash"
            v-else
          />
          <div class="app__waiting-list__item-list__container-text">
            <span
              class="badge app__waiting-list__item-list__badge"
              :class="badgeType(item.available_for_purchase)"
              :style="this.ifLoading"
            >
              {{ badgeText(item.available_for_purchase) }}
            </span>
            <span class="app__waiting-list__item-list__title">{{
              item.name
            }}</span>
            <span
              v-show="this.haveData(item.release_date)"
              class="app__waiting-list__item-list__description"
              >{{ this.langs.product["item_added"] }} <br v-show="isMobile" />
              {{ this.dateAdd(item.release_date) }}</span
            >
          </div>
        </section>
        <div class="app__waiting-list__item-list__container-buttons">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            @click="setDataModal('remove-one', item)"
            class="app__button app__button--secondary-outline app__button--no-margin app__waiting-list__button"
            :class="{
              app__loading: loading,
            }"
          >
            {{ this.langs.product["remove"] }}
          </button>
          <button
            class="app__button app__button--primary app__button--no-margin app__waiting-list__button--second"
            :class="{
              app__loading: loading,
              'app__button--secondary': !this.availableForPurchase(
                item.available_for_purchase
              ),
            }"
            @click="actionButton(item.url.https)"
          >
            {{ buttonText(item.available_for_purchase) }}
          </button>
        </div>
      </li>
    </ul>
    <app-empty-state v-else :messages="messages" />
    <app-modal :dataModal="this.dataModal" />
  </section>
</template>
