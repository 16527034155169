<script>
import { mapGetters } from "vuex";
import AppAlert from "@/features/bootstrap/Alert";
import AppSvg from "@/features/general/Svg";

export default {
  name: "appEmptyState",

  components: {
    AppSvg,
    AppAlert,
  },

  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters(["isMobile", "langs"]),

    classIfIsMobile() {
      return !this.isMobile
        ? "app__waiting-list__empty-state"
        : "app__waiting-list__empty-state app__waiting-list__empty-state__mobile";
    },
  },
};
</script>

<template>
  <section
    class="app__waiting-list__container-list"
    :class="this.classIfIsMobile"
  >
    <app-alert :messages="messages" />
    <div class="app__waiting-list__empty-state__content">
      <app-svg name="waiting-list-empty-state" />
      <span class="__title--2">
        {{ this.langs.product["empty-waiting-list"] }}
      </span>
      <p>
        {{ this.langs.product["notify-when-product-available"] }}
      </p>
    </div>
  </section>
</template>
